import React from 'react';
import PropTypes from 'prop-types';

import classnames from '../../utils/classnames';
import styles from './stylesheets/Row.module.css';

const propTypes = {
  hightlight: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  hightlight: true,
  className: null,
  children: null,
};

const Row = ({
  hightlight,
  className,
  children,
  ...rest
}) => (
  <tr
    className={classnames([
      hightlight && styles.hightlight,
      className,
    ])}
    {...rest}
  >
    {children}
  </tr>
);

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;

export default Row;
