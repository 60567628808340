import PropTypes from 'prop-types';

import BookInstanceShape from './BookInstanceShape';

export const bookPropTypes = {
  bookid: PropTypes.string,
  author: PropTypes.string,
  isbn: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  deleted: PropTypes.bool,
  instances: PropTypes.arrayOf(BookInstanceShape),
};

export default PropTypes.shape(bookPropTypes);
