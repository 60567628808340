import PropTypes from 'prop-types';

import BookStatusShape from './BookStatusShape';

export default PropTypes.shape({
  id: PropTypes.string,
  author: PropTypes.string,
  isbn: PropTypes.string,
  title: PropTypes.string,
  contributedby: PropTypes.string,
  status: BookStatusShape,
});
