import React from 'react';

import Actions from '../../components/Actions';
import Button from '../../components/Button';
import { LayoutSimple } from '../../template/Layout';
import Text from '../../components/Text';
import Title from '../../components/Title';

import { redirectToLogin } from '../../lib/auth';

const AccessDenied = () => (
  <LayoutSimple>
    <Title>Access Denied</Title>
    <Text muted>We sorry, you do not have access to this page.</Text>
    <Actions center top={20}>
      <Button
        primary
        onPress={redirectToLogin}
      >
        Sign in with your account
      </Button>
    </Actions>
  </LayoutSimple>
);

export default AccessDenied;
