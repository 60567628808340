import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';
import { SIZE_NORMAL } from '../../constants/sizes';
import SizesShape from '../../shapes/SizesShapes';
import classnames from '../../utils/classnames';
import styles from './stylesheets/Spinner.module.css';

const propTypes = {
  size: SizesShape,
  inverse: PropTypes.bool,
};

const defaultProps = {
  size: SIZE_NORMAL,
  inverse: false,
};

const Spinner = ({
  size,
  inverse,
}) => (
  <Box className={classnames([
    styles.circular,
    styles[`size-${size}`],
    inverse && styles.inverse,
  ])}
  >
    <span />
    <span />
    <span />
    <span />
  </Box>
);

Spinner.propTypes = propTypes;
Spinner.defaultProps = defaultProps;

export default Spinner;
