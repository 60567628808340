import React from 'react';
import PropTypes from 'prop-types';

import classnames from '../../utils/classnames';
import Box from '../Box';
import Spacing from '../Spacing';
import styles from './stylesheets/Actions.module.css';

const propTypes = {
  alignLeft: PropTypes.bool,
  center: PropTypes.bool,
  alignRight: PropTypes.bool,
  vertical: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  horizontal: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  left: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  right: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  bottom: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  top: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  children: PropTypes.node,
};

const defaultProps = {
  alignLeft: true,
  center: false,
  alignRight: false,
  vertical: null,
  horizontal: null,
  left: null,
  right: null,
  bottom: null,
  top: null,
  children: null,
};

const Actions = ({
  alignLeft,
  center,
  alignRight,
  children,
  ...rest
}) => (
  <Spacing {...rest}>
    <Box className={classnames(
      styles.container,
      alignLeft && styles.left,
      center && styles.center,
      alignRight && styles.right,
    )}
    >
      {children}
    </Box>
  </Spacing>
);

Actions.propTypes = propTypes;
Actions.defaultProps = defaultProps;

export default Actions;
