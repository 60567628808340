import React from 'react';

import { LoadingPage } from '../components/Loading';
import Loadable from '../utils/loadable';

const withLoadable = (importComponentFunction) => {
  const WrappedComponent = Loadable({
    loader: () => importComponentFunction(),
    loading: LoadingPage,
  });

  const WithLoadableHOC = props => (
    <WrappedComponent
      {...props}
    />
  );

  return WithLoadableHOC;
};

export default withLoadable;
