import React from 'react';
import PropTypes from 'prop-types';

import classnames from '../../utils/classnames';
import styles from './stylesheets/DOMButtonOrA.module.css';

const propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  isALink: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  className: null,
  href: null,
  isALink: false,
  type: 'button',
  children: null,
};

const DOMButtonOrA = ({
  className,
  href,
  isALink,
  type,
  children,
  ...rest
}) => {
  let Component;

  let componentProps = {
    className: classnames([
      styles.default,
      className,
    ]),
    ...rest,
  };

  if ((isALink && href) || href) {
    Component = 'a';
    componentProps = {
      href,
      ...componentProps,
    };
  } else {
    Component = 'button';
    componentProps = {
      type,
      ...componentProps,
    };
  }

  return (
    <Component {...componentProps}>
      {children}
    </Component>
  );
};

DOMButtonOrA.propTypes = propTypes;
DOMButtonOrA.defaultProps = defaultProps;

export default DOMButtonOrA;
