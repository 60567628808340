import React from 'react';
import PropTypes from 'prop-types';

import Route from './lib/Route';
import { Router, Switch } from './utils/router';

const propTypes = {
  /* eslint-disable comma-dangle */
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      component: PropTypes.func,
      exact: PropTypes.bool,
      auth: PropTypes.bool,
    })
  ),
};

const defaultProps = {
  routes: [],
};

class App extends React.Component {
  renderRoutes() {
    const { routes } = this.props;

    return routes.map(({
      auth,
      authRedirect,
      path,
      ...rest
    }) => (
      <Route
        key={path}
        needAuthentication={auth}
        redirectToProfile={authRedirect}
        path={path}
        {...rest}
      />
    ));
  }

  render() {
    const routes = this.renderRoutes();

    return (
      <Router>
        <Switch>
          {routes}
        </Switch>
      </Router>
    );
  }
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
