import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

const Box = ({
  children,
  ...rest
}) => (
  <div {...rest}>
    {children}
  </div>
);

Box.propTypes = propTypes;
Box.defaultProps = defaultProps;

export default Box;
