import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';
import Only from '../Only';
import Body from './Body';
import Footer from './Footer';
import Head from './Head';
import Summary from './Summary';
import HeadingShape from './shapes/HeadingShape';
import RowShape from './shapes/RowShape';
import styles from './stylesheets/Table.module.css';

const propTypes = {
  header: PropTypes.arrayOf(HeadingShape),
  rows: PropTypes.arrayOf(RowShape),
  footer: PropTypes.arrayOf(HeadingShape),
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  showSummary: PropTypes.bool,
  hoverRows: PropTypes.bool,
};

const defaultProps = {
  header: [],
  rows: [],
  footer: [],
  showHeader: true,
  showFooter: false,
  showSummary: false,
  hoverRows: true,
};

const Table = ({
  header,
  rows,
  footer,
  showHeader,
  showFooter,
  showSummary,
  hoverRows,
}) => (
  <Box className={styles.wrapper}>
    <table className={styles.default}>
      <Only when={showHeader && header.length}>
        <Head header={header} />
      </Only>
      <Body rows={rows} hightlight={hoverRows} />
      <Only when={showFooter && footer.length}>
        <Footer headers={footer} />
      </Only>
    </table>
    <Only when={showSummary}>
      <Summary
        current={10}
        total={100}
      />
    </Only>
  </Box>
);

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default Table;
