import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';
import Spinner from './Spinner';

import {
  SIZE_LARGE,
  SIZE_MEDIUM,
  SIZE_NORMAL,
  SIZE_SMALL,
  SIZE_TINIEST,
  SIZE_TINY,
} from '../../constants/sizes';

const propTypes = {
  tiny: PropTypes.bool,
  tiniest: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  inverse: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  tiny: false,
  tiniest: false,
  small: false,
  medium: false,
  large: false,
  inverse: false,
  className: null,
};

const Loading = ({
  tiny,
  tiniest,
  small,
  medium,
  large,
  className,
  ...rest
}) => {
  let size = SIZE_NORMAL;

  if (tiny) {
    size = SIZE_TINY;
  } else if (tiniest) {
    size = SIZE_TINIEST;
  } else if (small) {
    size = SIZE_SMALL;
  } else if (medium) {
    size = SIZE_MEDIUM;
  } else if (large) {
    size = SIZE_LARGE;
  }

  return (
    <Box className={className}>
      <Spinner
        size={size}
        {...rest}
      />
    </Box>
  );
};

Loading.propTypes = propTypes;
Loading.defaultProps = defaultProps;

export default Loading;
