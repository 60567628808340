import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';
import Rule from '../Rule';
import Text from '../Text';
import styles from './stylesheets/Summary.module.css';

const propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
};

const defaultProps = {
  current: 0,
  total: 0,
};

const Summary = ({
  current,
  total,
}) => {
  const string = `Showing ${current} — 30 of ${total} books`;

  return (
    <Box className={styles.content}>
      <Rule />
      <Text small muted>{string}</Text>
    </Box>
  );
};

Summary.propTypes = propTypes;
Summary.defaultProps = defaultProps;

export default Summary;
