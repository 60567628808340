import React from 'react';
import PropTypes from 'prop-types';

import styles from './stylesheets/Main.module.css';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

const Main = ({ children }) => (
  <main className={styles.layout}>
    {children}
  </main>
);

Main.propTypes = propTypes;
Main.defaultProps = defaultProps;

export default Main;
