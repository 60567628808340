import React from 'react';

import Link from '../../components/Link';
import styles from './stylesheets/Brand.module.css';

const Brand = () => (
  <Link className={styles.default} href="/">
    Alexandria
  </Link>
);

export default Brand;
