import React from 'react';
import PropTypes from 'prop-types';

import DOMButtonOrA from '../DOMButtonOrA';

import classnames from '../../utils/classnames';
import styles from './stylesheets/Dropdown.module.css';

const propTypes = {
  className: PropTypes.string,
  onPress: PropTypes.func,
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  className: null,
  onPress: () => null,
};

const Toggle = ({
  className,
  onPress,
  children,
  ...rest
}) => (
  <DOMButtonOrA
    className={classnames([
      styles.toggle,
      className,
    ])}
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    onClick={onPress}
    {...rest}
  >
    {children}
  </DOMButtonOrA>
);

Toggle.propTypes = propTypes;
Toggle.defaultProps = defaultProps;

export default Toggle;
