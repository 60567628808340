/* @TODO: split into dynamic loading */
import { ReactComponent as BookOpen } from './book-open.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as GitHub } from './github.svg';
import { ReactComponent as GitLab } from './gitlab.svg';
import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as Rss } from './rss.svg';
import { ReactComponent as Twitter } from './twitter.svg';

export default {
  'book-open': BookOpen,
  github: GitHub,
  gitlab: GitLab,
  mail: Mail,
  rss: Rss,
  twitter: Twitter,
  close: Close,
};
