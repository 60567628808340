import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';

import styles from './stylesheets/Dropdown.module.css';
import classnames from '../../utils/classnames';

const propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  show: false,
};

const Dropdown = ({
  show,
  children,
}) => (
  <Box
    className={classnames([
      styles.default,
      show && styles.show,
    ])}
  >
    {children}
  </Box>
);

Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;

export default Dropdown;
