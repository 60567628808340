import React from 'react';
import PropTypes from 'prop-types';

import classnames from '../../utils/classnames';
import styles from './stylesheets/Title.module.css';

const propTypes = {
  level: PropTypes.number,
  className: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
  ]),
};

const defaultProps = {
  level: 2,
  text: null,
  className: null,
  children: null,
};

const Title = ({
  level,
  text,
  className,
  children,
  ...rest
}) => {
  const Level = `h${level}`;

  return (
    <Level
      className={classnames([
        styles[`level-${level}`],
        className,
      ])}
      {...rest}
    >
      {text || children}
    </Level>
  );
};

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

export default Title;
