import React from 'react';
import PropTypes from 'prop-types';

import { Route as NormalRoute } from '../../utils/router';
import PrivateRoute from '../PrivateRoute';
import RedirectRoute from '../RedirectRoute';

const propTypes = {
  needAuthentication: PropTypes.bool,
  redirectToProfile: PropTypes.bool,
  path: PropTypes.string.isRequired,
};

const defaultProps = {
  needAuthentication: false,
  redirectToProfile: false,
};

const Route = ({
  needAuthentication,
  redirectToProfile,
  ...rest
}) => {
  if (needAuthentication) {
    return (
      <PrivateRoute {...rest} />
    );
  }

  if (redirectToProfile) {
    return (
      <RedirectRoute {...rest} />
    );
  }

  return (
    <NormalRoute {...rest} />
  );
};

Route.propTypes = propTypes;
Route.defaultProps = defaultProps;

export default Route;
