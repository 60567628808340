import React from 'react';
import PropTypes from 'prop-types';

import { ALIGN_START } from '../../constants/align-items';
import { JUSTIFY_START } from '../../constants/justify-content';
import AlignItemsShape from '../../shapes/AlignItemsShape';
import JustifyContentShape from '../../shapes/JustifyContentShape';
import classnames from '../../utils/classnames';
import Box from '../Box';
import styles from './stylesheets/FlexBar.module.css';

const propTypes = {
  inline: PropTypes.bool,
  alignItems: AlignItemsShape,
  justifyContent: JustifyContentShape,
  children: PropTypes.node,
};

const defaultProps = {
  inline: false,
  alignItems: ALIGN_START,
  justifyContent: JUSTIFY_START,
  children: null,
};

const FlexBar = ({
  inline,
  alignItems,
  justifyContent,
  children,
  ...rest
}) => (
  <Box
    className={classnames([
      styles.flex,
      inline && styles.inline,
      alignItems && styles[`align-items-${alignItems}`],
      justifyContent && styles[`justify-content-${justifyContent}`],
    ])}
    style={rest}
  >
    {children}
  </Box>
);

FlexBar.propTypes = propTypes;
FlexBar.defaultProps = defaultProps;

export default FlexBar;
