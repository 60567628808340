import React from 'react';

import Alert from '../../../components/Alert';
import Loading from '../../../components/Loading';
import Table from '../../../components/Table';

import service from '../../../services/profile';

import Book from './Book';


import ProfileShape from '../../../shapes/ProfileShape';

const propTypes = {
  profile: ProfileShape,
};

const defaultProps = {
  profile: null,
};

class Books extends React.Component {
  constructor(props) {
    super(props);
    this.promise = null;
    this.state = {
      loading: true,
      books: [],
    };
    this.onRequestComplete = this.onRequestComplete.bind(this);
    this.requestBooks = this.requestBooks.bind(this);
  }

  componentDidMount() {
    this.requestBooks();
  }

  onRequestComplete({ data }) {
    this.setState({
      loading: false,
      books: data || [],
    });
  }

  requestBooks() {
    this.setState({
      loading: true,
      books: [],
    });

    this.promise = service.getBooks();
    this.promise
      .then(this.onRequestComplete);
  }

  renderBooks() {
    const {
      profile: {
        nickname,
      },
    } = this.props;

    const { books } = this.state;

    return books.map(({
      bookid,
      ...rest
    }) => ({
      key: bookid,
      columns: [{
        name: 'book',
        value: (
          <Book
            key={bookid}
            bookid={bookid}
            user={nickname}
            updateCallback={this.requestBooks}
            {...rest}
          />
        ),
      }],
    }));
  }

  render() {
    const {
      loading,
      books,
    } = this.state;

    if (loading) {
      return (
        <Loading />
      );
    }

    if (!books || !books.length) {
      return (
        <Alert info>
          You have no books borrowed.
        </Alert>
      );
    }

    const header = [{
      name: 'book',
      value: 'Books I have borrowed',
    }];

    const rows = this.renderBooks();

    return (
      <Table
        header={header}
        rows={rows}
      />
    );
  }
}

Books.propTypes = propTypes;
Books.defaultProps = defaultProps;

export default Books;
