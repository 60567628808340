import PropTypes from 'prop-types';

import {
  SIZE_LARGE, SIZE_MEDIUM, SIZE_NORMAL, SIZE_SMALL, SIZE_TINIEST, SIZE_TINY,
} from '../constants/sizes';

export default PropTypes.oneOf([
  SIZE_NORMAL,
  SIZE_TINY,
  SIZE_TINIEST,
  SIZE_SMALL,
  SIZE_MEDIUM,
  SIZE_LARGE,
]);
