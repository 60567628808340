import PropTypes from 'prop-types';

import {
  JUSTIFY_AROUND, JUSTIFY_BETWEEN, JUSTIFY_CENTER, JUSTIFY_END, JUSTIFY_START,
} from '../constants/justify-content';

export default PropTypes.oneOf([
  JUSTIFY_START,
  JUSTIFY_END,
  JUSTIFY_CENTER,
  JUSTIFY_BETWEEN,
  JUSTIFY_AROUND,
]);
