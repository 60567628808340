import React from 'react';
import PropTypes from 'prop-types';

import Box from '../../components/Box';

import classnames from '../../utils/classnames';
import styles from './stylesheets/Layout.module.css';

import './stylesheets/reset.css';
import './stylesheets/variables.css';
import './stylesheets/common.css';

const propTypes = {
  flex: PropTypes.bool,
  horizontallyCentered: PropTypes.bool,
  verticalCentered: PropTypes.bool,
  autoHeight: PropTypes.bool,
  children: PropTypes.node,
};

const defaultProps = {
  flex: false,
  horizontallyCentered: false,
  verticalCentered: false,
  autoHeight: false,
  children: null,
};

const Base = ({
  flex,
  horizontallyCentered,
  verticalCentered,
  autoHeight,
  children,
}) => (
  <Box
    id="l"
    className={classnames([
      styles.common,
      flex && styles.flex,
      horizontallyCentered && styles.horizontallyCentered,
      verticalCentered && styles.verticalCentered,
      autoHeight && styles.auto,
    ])}
  >
    {children}
  </Box>
);

Base.propTypes = propTypes;
Base.defaultProps = defaultProps;

export default Base;
