const hostname = window && window.location && window.location.hostname;

let endpoint;
let port;

switch (hostname) {
  case process.env.REACT_APP_SERVER_LOCALHOST:
  case process.env.REACT_APP_SERVER_DEBIAN_TESTING:
    endpoint = `//${process.env.REACT_APP_SERVER_DEBIAN_TESTING}`;
    port = 8080;
    break;

  case process.env.REACT_APP_SERVER_STAGING:
    endpoint = `//${process.env.REACT_APP_SERVER_STAGING}`;
    port = 443;
    break;

  default:
    endpoint = `//${process.env.REACT_APP_SERVER_PRODUCTION}`;
    port = 443;
    break;
}

export const API_ROOT = `${endpoint}:${port}`;

export const API_PORT = port;

export const API_BASE = 'api';

export const API_ENDPOINT = `${API_ROOT}/${API_BASE}`;

export default {
  API_ROOT,
  API_BASE,
  API_ENDPOINT,
};
