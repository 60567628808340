import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';
import Text from '../Text';
import Loading from './Loading';
import styles from './stylesheets/Interface.module.css';

const propTypes = {
  message: PropTypes.string,
};

const defaultProps = {
  message: null,
};

const Interface = ({
  message,
}) => (
  <Box className={styles.wrapper}>
    {message && (
      <Text>{message}</Text>
    )}
    <Box className={styles.content}>
      <Loading />
    </Box>
  </Box>
);

Interface.propTypes = propTypes;
Interface.defaultProps = defaultProps;

export default Interface;
