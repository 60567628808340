import React from 'react';
import PropTypes from 'prop-types';

import styles from './stylesheets/Rule.module.css';

const propTypes = {
  top: PropTypes.number,
  bottom: PropTypes.number,
  spacing: PropTypes.number,
};

const defaultProps = {
  top: -1,
  bottom: -1,
  spacing: -1,
};

const Rule = ({
  top,
  bottom,
  spacing,
}) => {
  const style = {};

  if (spacing >= 0 || top >= 0) {
    style.marginTop = spacing >= 0 ? spacing : top;
  }

  if (spacing >= 0 || bottom >= 0) {
    style.marginBottom = spacing >= 0 ? spacing : bottom;
  }

  return (
    <hr
      className={styles.default}
      style={style}
    />
  );
};

Rule.propTypes = propTypes;
Rule.defaultProps = defaultProps;

export default Rule;
