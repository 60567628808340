import React from 'react';
import PropTypes from 'prop-types';

import Base from './Base';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

const Simple = ({ children }) => (
  <Base flex verticalCentered horizontallyCentered>
    {children}
  </Base>
);

Simple.propTypes = propTypes;
Simple.defaultProps = defaultProps;

export default Simple;
