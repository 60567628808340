import React from 'react';

import Actions from '../../components/Actions';
import Button from '../../components/Button';
import Only from '../../components/Only';
import Spacing from '../../components/Spacing';

import ProfileShape from '../../shapes/ProfileShape';

import { isAdmin } from '../../lib/auth';

const propTypes = {
  profile: ProfileShape,
};

const defaultProps = {
  profile: null,
};

const ProfileActions = ({
  profile: {
    email,
  },
}) => (
  <Actions top={20}>
    <Spacing right={10}>
      <Button
        primary
        to="/books"
      >
        Browse books
      </Button>
    </Spacing>
    <Only when={isAdmin(email)}>
      <Button
        secondary
        outline
        to="/manage"
      >
        Manage books
      </Button>
    </Only>
  </Actions>
);

ProfileActions.propTypes = propTypes;
ProfileActions.defaultProps = defaultProps;

export default ProfileActions;
