import React from 'react';
import PropTypes from 'prop-types';

import icons from '../../assets/icons';
import classnames from '../../utils/classnames';
import styles from './stylesheets/Icon.module.css';

const propTypes = {
  name: PropTypes.string.isRequired,
  block: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  extraLarge: PropTypes.bool,
};

const defaultProps = {
  block: false,
  small: false,
  medium: false,
  large: false,
  extraLarge: false,
};

const Icon = ({
  name,
  block,
  small,
  medium,
  large,
  extraLarge,
}) => {
  const IconComponent = icons[name];

  return (
    <span
      aria-hidden="true"
      className={classnames([
        styles.wrapper,
        block && styles.block,
        small && styles.small,
        medium && styles.medium,
        large && styles.large,
        extraLarge && styles.extraLarge,
      ])}
    >
      <IconComponent />
    </span>
  );
};

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
