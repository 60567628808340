/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Footer from '../Footer';
import Header from '../Header';
import Main from '../Main';
import Navbar from '../Navbar';
import Base from './Base';

import ProfileShape from '../../shapes/ProfileShape';

const propTypes = {
  auto: PropTypes.bool,
  profile: ProfileShape,
  footerOptions: PropTypes.object,
  headerOptions: PropTypes.object,
  children: PropTypes.node,
};

const defaultProps = {
  auto: false,
  profile: null,
  footerOptions: {},
  headerOptions: {},
  children: null,
};

const Layout = ({
  auto,
  profile,
  footerOptions,
  headerOptions,
  children,
}) => (
  <Base autoHeight={auto}>
    <Header {...headerOptions}>
      <Navbar profile={profile} />
    </Header>
    <Main>
      {children}
    </Main>
    <Footer {...footerOptions} />
  </Base>
);

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
