import React from 'react';
import PropTypes from 'prop-types';

import md5 from 'js-md5';

import { API_ROOT } from '../../constants/api-config';
import { GRAVATAR_URL } from '../../constants';
import service from '../../services/profile';
import admins from '../../config/admins';

const defaultState = {
  isAuthenticated: false,
  profile: null,
  loadingAuth: true,
};

export const authPropTypes = {
  isAuthenticated: PropTypes.bool,
  profile: PropTypes.object,
  loadingAuth: PropTypes.bool,
};

export const authDefaultProps = {
  ...defaultState,
};

let state = defaultState;

const subscribers = [];

const unsubscribe = (subscriber) => {
  const index = subscribers.findIndex(subscriber);
  if (index >= 0) {
    subscribers.splice(index, 1);
  }
};

const subscribe = (subscriber) => {
  subscribers.push(subscriber);
  return () => unsubscribe(subscriber);
};

export const update = (newState) => {
  state = newState;
  subscribers.forEach(subscriber => subscriber());
};

export const isAdmin = email => admins.some(({ email: adminEmail }) => email === adminEmail);

export const redirectToLogin = () => {
  window.location = `${API_ROOT}/login`;
};

export const gravatarHashEmail = email => md5(email.trim().toLowerCase());

export const gravatar = (email = '') => `${GRAVATAR_URL}/${gravatarHashEmail(email)}`;

const withAuth = (Component) => {
  class WithAuthHOC extends React.Component {
    constructor(props) {
      super(props);
      this.promise = null;
      this.forceUpdate = this.forceUpdate.bind(this);
      this.unsubscribe = () => null;
    }

    componentDidMount() {
      this.unsubscribe = subscribe(this.forceUpdate);
      this.promise = service.checkAuth();
      this.promise
        .then(({ data: profile }) => update({
          profile,
          isAuthenticated: true,
          loadingAuth: false,
        }))
        .catch(() => update({
          ...defaultState,
          loadingAuth: false,
        }));
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    render() {
      const props = {
        ...this.props,
        auth: state,
      };

      return (
        <Component {...props} />
      );
    }
  }

  WithAuthHOC.authPropTypes = authPropTypes;
  WithAuthHOC.authDefaultProps = authDefaultProps;

  return WithAuthHOC;
};

export default withAuth;
