import React from 'react';
import PropTypes from 'prop-types';

import classnames from '../../utils/classnames';
import Box from '../Box';
import styles from './stylesheets/Container.module.css';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  className: null,
  children: null,
};

const Container = ({
  className,
  children,
  ...rest
}) => (
  <Box
    className={classnames([
      styles.default,
      className,
    ])}
    {...rest}
  >
    {children}
  </Box>
);

Container.propTypes = propTypes;
Container.defaultProps = defaultProps;

export default Container;
