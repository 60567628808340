import React from 'react';
import PropTypes from 'prop-types';

import DOMButtonOrA from '../DOMButtonOrA';

const propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  href: null,
  children: null,
};

const Link = ({
  href,
  children,
  ...rest
}) => (
  <DOMButtonOrA
    href={href}
    isALink={href !== null}
    {...rest}
  >
    {children}
  </DOMButtonOrA>
);

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;
