/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';

import isFunction from '../../utils/isFunction';
import Cell from './Cell';
import Row from './Row';
import RowShape from './shapes/RowShape';

const propTypes = {
  rows: PropTypes.arrayOf(RowShape),
  hightlight: PropTypes.bool,
};

const defaultProps = {
  rows: [],
  hightlight: true,
};

class Body extends React.Component {
  renderCell({ value }) {
    return isFunction(value) ? value() : value;
  }

  renderColumns(columns) {
    return columns.map(({ name, options, ...rest }) => (
      <Cell key={`.cell-${name}`} {...options}>
        {this.renderCell({
          name,
          ...rest,
        })}
      </Cell>
    ));
  }

  renderRows() {
    const {
      hightlight,
      rows,
    } = this.props;

    return rows.map(({ key, columns }) => (
      <Row key={`.row-${key}`} hightlight={hightlight}>
        {this.renderColumns(columns)}
      </Row>
    ));
  }

  render() {
    const rows = this.renderRows();

    return (
      <tbody>
        {rows}
      </tbody>
    );
  }
}

Body.propTypes = propTypes;
Body.defaultProps = defaultProps;

export default Body;
