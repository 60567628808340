import React from 'react';

import Alert from '../../components/Alert';
import Box from '../../components/Box';
import Column from '../../components/Column';
import Container from '../../components/Container';
import Row from '../../components/Row';
import Rule from '../../components/Rule';
import Text from '../../components/Text';
import Title from '../../components/Title';
import Layout from '../../template/Layout';
import { LoadingInterface } from '../../components/Loading';

import ProfileShape from '../../shapes/ProfileShape';

import Avatar from './Avatar';
import Books from './Books';
import ProfileActions from './ProfileActions';

import styles from './stylesheets/Profile.module.css';
import service from '../../services/profile';
import parseError from '../../utils/parseError';

const propTypes = {
  profile: ProfileShape,
};

const defaultProps = {
  profile: null,
};

class Profile extends React.Component {
  constructor(props) {
    super(props);
    const { profile } = props;
    this.promise = null;
    this.state = {
      profile,
      loading: profile === null,
      error: false,
      errorMessage: null,
    };
    this.onRequestComplete = this.onRequestComplete.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    const { profile } = this.props;
    if (!profile) {
      this.promise = service.getProfile();
      this.promise
        .then(result => this.onRequestComplete(result.data || []))
        .catch(this.onError);
    }
  }

  onRequestComplete(profile) {
    this.setState({
      loading: false,
      profile,
    });
  }

  onError(error) {
    const message = parseError(error);

    this.setState({
      loading: false,
      error: true,
      errorMessage: message,
    });
  }

  renderError() {
    const { errorMessage } = this.state;

    return (
      <Alert error>
        {errorMessage}
      </Alert>

    );
  }

  renderProfile() {
    const { profile } = this.state;

    const {
      name,
      nickname,
      email,
    } = profile;

    return (
      <Box>
        <Row>
          <Column size={2}>
            <Avatar email={email} name={name} />
          </Column>
          <Column>
            <Title>{`Hi, ${nickname}`}</Title>
            <Text muted>Welcome to Alexandria</Text>
            <Rule />
            <Books profile={profile} />
            <Rule />
            <ProfileActions profile={profile} />
          </Column>
        </Row>
      </Box>
    );
  }

  render() {
    const { profile } = this.state;

    const {
      error,
      loading,
    } = this.state;

    let content;
    if (loading) {
      content = (<LoadingInterface message="Loading profile" />);
    } else if (error) {
      content = this.renderError();
    } else {
      content = this.renderProfile();
    }

    return (
      <Layout auto profile={profile}>
        <Container className={styles.content}>
          {content}
        </Container>
      </Layout>
    );
  }
}

Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;

export default Profile;
