import PropTypes from 'prop-types';

import {
  ALIGN_AROUND, ALIGN_BASELINE, ALIGN_CENTER, ALIGN_END, ALIGN_START, ALIGN_STRETCH,
} from '../constants/align-items';

export default PropTypes.oneOf([
  ALIGN_START,
  ALIGN_CENTER,
  ALIGN_END,
  ALIGN_AROUND,
  ALIGN_BASELINE,
  ALIGN_STRETCH,
]);
