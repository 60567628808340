import React from 'react';
import PropTypes from 'prop-types';

import DOMButtonOrA from '../DOMButtonOrA';

import styles from './stylesheets/Dropdown.module.css';

const propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  to: null,
};

const Item = ({
  to,
  children,
  ...rest
}) => (
  <DOMButtonOrA
    href={to}
    className={styles.item}
    {...rest}
  >
    {children}
  </DOMButtonOrA>
);

Item.propTypes = propTypes;
Item.defaultProps = defaultProps;

export default Item;
