import withLoadable from '../lib/withLoadable';

const routes = [
  {
    path: '/',
    component: withLoadable(() => import('../pages/welcome')),
    exact: true,
    authRedirect: true,
  }, {
    path: '/authorization/:token',
    component: withLoadable(() => import('../pages/authorization')),
    exact: true,
  }, {
    path: '/profile',
    component: withLoadable(() => import('../pages/profile')),
    exact: true,
    auth: true,
  }, {
    path: '/books',
    component: withLoadable(() => import('../pages/books')),
    exact: true,
    auth: true,
  }, {
    path: '/manage',
    component: withLoadable(() => import('../pages/manage')),
    exact: true,
    auth: true,
  }, {
    path: '*',
    component: withLoadable(() => import('../pages/not-found')),
  },
];

export default routes;
