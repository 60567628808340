import React from 'react';
import PropTypes from 'prop-types';

import styles from './stylesheets/Cell.module.css';

const propTypes = {
  type: PropTypes.oneOf(['header', 'normal']),
  children: PropTypes.node,
};

const defaultProps = {
  type: 'normal',
  children: null,
};

const Cell = ({
  type,
  children,
  ...rest
}) => {
  const cellType = type === 'normal' ? 'd' : 'h';
  const Type = `t${cellType}`;

  return (
    <Type className={styles[Type]} {...rest}>
      {children}
    </Type>
  );
};

Cell.propTypes = propTypes;
Cell.defaultProps = defaultProps;

export default Cell;
