import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import Column from '../../components/Column';
import Container from '../../components/Container';
import Row from '../../components/Row';
import Rule from '../../components/Rule';
import Text from '../../components/Text';
import Channels from '../Channels';
import styles from './stylesheets/Footer.module.css';

const propTypes = {
  noMargin: PropTypes.bool,
  children: PropTypes.node,
};

const defaultProps = {
  noMargin: false,
  children: null,
};

const Footer = ({
  noMargin,
  children,
  ...rest
}) => (
  <footer
    className={classnames([
      styles.default,
      noMargin && styles.noMargin,
    ])}
    {...rest}
  >
    {children}
    <Rule top={0} />
    <Container>
      <Row>
        <Column>
          <Text>We love feedback! Any thoughts? Get in touch with us!</Text>
          <Text muted>IRC channel: #cad (on OFTC)</Text>
        </Column>
        <Column autoLarge>
          <Channels />
        </Column>
      </Row>
    </Container>
  </footer>
);

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
