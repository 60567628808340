const parseError = (error) => {
  if (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { data } = error.response;
      return data;
    }
    if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest
    }
    return error.message;
  }
  return 'An undefined error ocurred';
};

export default parseError;
