import React from 'react';
import PropTypes from 'prop-types';

import Column from '../../../components/Column';
import Row from '../../../components/Row';

import Return from './Return';

import { bookPropTypes } from '../../../shapes/BookShape';

const propTypes = {
  ...bookPropTypes,
  user: PropTypes.string.isRequired,
  updateCallback: PropTypes.func,
};

const defaultProps = {
  updateCallback: () => null,
};

class Book extends React.Component {
  constructor(props) {
    super(props);
    this.requestBooks = this.requestBooks.bind(this);
  }

  getInstance() {
    const {
      user,
      instances,
    } = this.props;

    return instances.find(({ usedby }) => usedby === user);
  }

  requestBooks() {
    const { updateCallback } = this.props;
    updateCallback();
  }

  render() {
    const {
      bookid,
      title,
    } = this.props;

    const instance = this.getInstance();

    return (
      <Row alignItems="center">
        <Column>
          {title}
        </Column>
        <Column auto>
          <Return
            bookid={bookid}
            instance={instance}
            onSuccess={this.requestBooks}
          />
        </Column>
      </Row>
    );
  }
}

Book.propTypes = propTypes;
Book.defaultProps = defaultProps;

export default Book;
