import * as api from '../../constants/api';
import axios from '../../utils/axios';

export const getBooks = (manage = false) => axios({
  method: 'get',
  headers: axios.defaults.headers,
  url: manage ? api.manageBooks : api.books,
});

export const getBook = bookid => axios({
  method: 'get',
  headers: axios.defaults.headers,
  url: `${api.books}/${bookid}`,
});

export const getInstances = bookid => axios({
  method: 'get',
  headers: axios.defaults.headers,
  url: `${api.books}/${bookid}`,
});

export const createBook = data => axios({
  method: 'post',
  headers: axios.defaults.headers,
  url: `${api.books}`,
  data,
});

export const updateBook = (bookid, data) => axios({
  method: 'put',
  headers: axios.defaults.headers,
  url: `${api.books}/${bookid}`,
  data,
});

export const deleteBook = bookid => axios({
  method: 'delete',
  headers: axios.defaults.headers,
  url: `${api.books}/${bookid}`,
});

export const checkoutBook = (bookid, instanceid) => axios({
  method: 'put',
  headers: axios.defaults.headers,
  url: `${api.books}/check/C/${bookid}/${instanceid}`,
});

export const returnBook = (bookid, instanceid) => axios({
  method: 'put',
  headers: axios.defaults.headers,
  url: `${api.books}/check/R/${bookid}/${instanceid}`,
});

export const deleteInstance = (bookid, instanceid) => axios({
  method: 'delete',
  headers: axios.defaults.headers,
  url: `${api.instances}/${bookid}/${instanceid}`,
});

export const deleteInstancePermanently = (bookid, data) => updateBook(bookid, data);

export default {
  getBooks,
  getBook,
  createBook,
  updateBook,
  deleteBook,
  checkoutBook,
  deleteInstance,
};
