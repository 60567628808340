import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';

import service from '../../../services/books';

import BookInstanceShape from '../../../shapes/BookFullShape';

const propTypes = {
  bookid: PropTypes.string.isRequired,
  instance: BookInstanceShape,
  onSuccess: PropTypes.func,
};

const defaultProps = {
  instance: null,
  onSuccess: () => null,
};

class Return extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doingReturn: false,
    };
    this.promise = null;
    this.onReturnPress = this.onReturnPress.bind(this);
    this.onReturnSuccess = this.onReturnSuccess.bind(this);
    this.onReturnError = this.onReturnError.bind(this);
  }

  onReturnError() {
    this.setState({
      doingReturn: false,
    });
  }

  onReturnPress(event) {
    event.preventDefault();
    this.returnBook();
  }

  onReturnSuccess() {
    const { onSuccess } = this.props;

    onSuccess();
  }

  returnBook() {
    const {
      bookid,
      instance: {
        instanceid,
      },
    } = this.props;

    if (instanceid) {
      this.setState({
        doingReturn: true,
      });

      this.promise = service.returnBook(bookid, instanceid);
      this.promise
        .then(this.onReturnSuccess)
        .catch(this.onReturnError);
    }
  }

  render() {
    const { instance } = this.props;

    const {
      doingReturn,
    } = this.state;

    if (!instance) {
      return null;
    }

    return (
      <Button
        small
        secondary
        outline
        loading={doingReturn}
        disabled={doingReturn}
        onPress={this.onReturnPress}
      >
        Return this book
      </Button>
    );
  }
}

Return.propTypes = propTypes;
Return.defaultProps = defaultProps;

export default Return;
