import React from 'react';
import PropTypes from 'prop-types';

import { Route } from '../../utils/router';
import Profile from '../../pages/profile';

import withAuth, { authPropTypes } from '../auth';

const propTypes = {
  ...authPropTypes,
  component: PropTypes.func.isRequired,
};

const RedirectRoute = ({
  auth: {
    profile,
    isAuthenticated,
    loadingAuth,
  },
  component: Component,
  path,
  ...rest
}) => {
  if (loadingAuth) {
    return null;
  }

  if (isAuthenticated && path !== '/profile') {
    return (
      <Profile profile={profile} />
    );
  }

  return (
    <Route
      path={path}
      {...rest}
      render={props => (
        <Component {...props} />
      )}
    />
  );
};

RedirectRoute.propTypes = propTypes;

export default withAuth(RedirectRoute);
