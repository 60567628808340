import React from 'react';
import PropTypes from 'prop-types';

import classnames from '../../utils/classnames';
import DOMButtonOrA from '../DOMButtonOrA';
import Loading from '../Loading';
import Spacing from '../Spacing';
import styles from './stylesheets/Button.module.css';

const propTypes = {
  // button sizes
  normal: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  extraLarge: PropTypes.bool,

  // button variations
  basic: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  danger: PropTypes.bool,
  outline: PropTypes.bool,

  to: PropTypes.string,
  block: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,

  children: PropTypes.node,
};

const defaultProps = {
  normal: true,
  small: false,
  medium: false,
  large: false,
  extraLarge: false,

  basic: true,
  primary: false,
  secondary: false,
  danger: false,
  outline: false,

  to: '',
  block: false,
  className: null,
  loading: false,
  disabled: false,
  onPress: () => null,
  children: null,
};

const renderButtonLoading = () => (
  <Spacing right={5}>
    <Loading tiniest key=".button-loading" />
  </Spacing>
);

const Button = ({
  normal,
  small,
  medium,
  large,
  extraLarge,

  basic,
  primary,
  secondary,
  danger,
  outline,

  to,
  block,
  className,
  loading,
  onPress,
  disabled,
  children,
  ...rest
}) => (
  <DOMButtonOrA
    isALink={to !== ''}
    href={to}
    onClick={onPress}
    className={classnames([
      styles.normal,
      to && styles.link,
      block && styles.block,
      // sizing
      normal && styles.normal,
      small && styles.small,
      medium && styles.medium,
      large && styles.large,
      extraLarge && styles['extra-large'],

      // colors
      basic && styles.default,
      primary && styles.primary,
      secondary && styles.secondary,
      danger && styles.danger,

      // outline
      outline && styles.outline,

      className,
      loading && styles.loading,
      disabled && styles.disabled,
    ])}
    {...rest}
  >
    {loading && renderButtonLoading()}
    {children}
  </DOMButtonOrA>
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
