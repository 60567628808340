import React from 'react';
import PropTypes from 'prop-types';

import classnames from '../../utils/classnames';
import Box from '../Box';
import styles from './stylesheets/Column.module.css';

const propTypes = {
  // column auto size flags
  auto: PropTypes.bool,
  autoSmall: PropTypes.bool,
  autoMedium: PropTypes.bool,
  autoLarge: PropTypes.bool,
  autoExtraLarge: PropTypes.bool,

  // column offsets
  offset: PropTypes.number,
  offsetSmall: PropTypes.number,
  offsetMedium: PropTypes.number,
  offsetLarge: PropTypes.number,
  offsetExtraLarge: PropTypes.number,

  // column orders
  order: PropTypes.number,
  orderSmall: PropTypes.number,
  orderMedium: PropTypes.number,
  orderLarge: PropTypes.number,
  orderExtraLarge: PropTypes.number,

  // column sizes
  size: PropTypes.number,
  sizeSmall: PropTypes.number,
  sizeMedium: PropTypes.number,
  sizeLarge: PropTypes.number,
  sizeExtraLarge: PropTypes.number,

  noGutter: PropTypes.bool,
  children: PropTypes.node,
};

const defaultProps = {
  auto: false,
  autoSmall: false,
  autoMedium: false,
  autoLarge: false,
  autoExtraLarge: false,
  offset: null,
  offsetSmall: null,
  offsetMedium: null,
  offsetLarge: null,
  offsetExtraLarge: null,
  order: null,
  orderSmall: null,
  orderMedium: null,
  orderLarge: null,
  orderExtraLarge: null,
  size: null,
  sizeSmall: null,
  sizeMedium: null,
  sizeLarge: null,
  sizeExtraLarge: null,
  noGutter: false,
  children: null,
};

const Column = ({
  auto,
  autoSmall,
  autoMedium,
  autoLarge,
  autoExtraLarge,

  offset,
  offsetSmall,
  offsetMedium,
  offsetLarge,
  offsetExtraLarge,

  order,
  orderSmall,
  orderMedium,
  orderLarge,
  orderExtraLarge,

  size,
  sizeSmall,
  sizeMedium,
  sizeLarge,
  sizeExtraLarge,

  noGutter,
  children,
}) => (
  <Box className={classnames([
    styles.default,

    auto && styles.auto,
    autoSmall && styles['size-sm-auto'],
    autoMedium && styles['size-md-auto'],
    autoLarge && styles['size-lg-auto'],
    autoExtraLarge && styles['size-xl-auto'],

    offset && styles[`offset-${offset}`],
    offsetSmall && styles[`offset-sm-${offsetSmall}`],
    offsetMedium && styles[`offset-md-${offsetMedium}`],
    offsetLarge && styles[`offset-lg-${offsetLarge}`],
    offsetExtraLarge && styles[`offset-xl-${offsetExtraLarge}`],

    order && styles[`order-${order}`],
    orderSmall && styles[`order-sm-${orderSmall}`],
    orderMedium && styles[`order-md-${orderMedium}`],
    orderLarge && styles[`order-lg-${orderLarge}`],
    orderExtraLarge && styles[`order-xl-${orderExtraLarge}`],

    size && styles[`size-${size}`],
    sizeSmall && styles[`size-sm-${sizeSmall}`],
    sizeMedium && styles[`size-md-${sizeMedium}`],
    sizeLarge && styles[`size-lg-${sizeLarge}`],
    sizeExtraLarge && styles[`size-xl-${sizeExtraLarge}`],

    noGutter && styles.noGutter,
  ])}
  >
    {children}
  </Box>
);

Column.propTypes = propTypes;
Column.defaultProps = defaultProps;

export default Column;
