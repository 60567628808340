import React from 'react';

import Icon from '../../components/Icon';

const GitHubLink = () => (<Icon name="github" />);

const GitLabLink = () => (<Icon name="gitlab" />);

const MailLink = () => (<Icon name="mail" />);

const RSSLink = () => (<Icon name="rss" />);

const TwitterLink = () => (<Icon name="twitter" />);

export default [
  {
    id: 'gitub',
    url: 'https://github.com/digitalautonomy',
    title: 'Contribute to CAD\'s GitHub repo',
    Component: GitHubLink,
  },
  {
    id: 'gitlab',
    url: 'https://gitlab.autonomia.digital/',
    title: 'CAD GitLab',
    Component: GitLabLink,
  },
  {
    id: 'mail',
    url: 'mailto:contact@autonomia.digital',
    title: 'Contact by email',
    Component: MailLink,
  },
  {
    id: 'rss',
    url: 'https://autonomia.digital/feed.xml',
    title: 'Use web feed',
    Component: RSSLink,
  },
  {
    id: 'twitter',
    url: 'https://twitter.com/somos_CAD',
    title: 'Contact by Twitter',
    Component: TwitterLink,
  },
];
