import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';

import styles from './stylesheets/Dropdown.module.css';
import classnames from '../../utils/classnames';

const propTypes = {
  left: PropTypes.bool,
  right: PropTypes.bool,
  show: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  left: true,
  right: false,
  show: false,
};

const Menu = ({
  left,
  right,
  show,
  children,
  ...rest
}) => (
  <Box
    className={classnames([
      styles.menu,
      left && styles['menu-left'],
      right && styles['menu-right'],
      show && styles['menu-show'],
    ])}
    {...rest}
  >
    {children}
  </Box>
);

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

export default Menu;
