import React from 'react';
import PropTypes from 'prop-types';

import classnames from '../../utils/classnames';
import Box from '../Box';
import Text from '../Text';
import styles from './stylesheets/Alert.module.css';

const propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  info: PropTypes.bool,
  warning: PropTypes.bool,
  children: PropTypes.node,
};

const defaultProps = {
  error: false,
  success: false,
  info: false,
  warning: false,
  children: null,
};

const Alert = ({
  error,
  success,
  info,
  warning,
  children,
}) => (
  <Box className={classnames([
    styles.default,
    success && styles.success,
    error && styles.error,
    info && styles.info,
    warning && styles.warning,
  ])}
  >
    <Text>
      {children}
    </Text>
  </Box>
);

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;

export default Alert;
