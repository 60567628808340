import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';

const propTypes = {
  top: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  bottom: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  left: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  right: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  vertical: PropTypes.number,
  horizontal: PropTypes.number,
  children: PropTypes.node,
};

const defaultProps = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  vertical: 0,
  horizontal: 0,
  children: null,
};

const Spacing = ({
  top,
  bottom,
  left,
  right,
  vertical,
  horizontal,
  children,
  ...rest
}) => {
  const style = {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  };

  if (top || vertical) { style.marginTop = top || vertical; }
  if (bottom || vertical) { style.marginBottom = bottom || vertical; }

  if (left || horizontal) { style.marginLeft = left || horizontal; }
  if (right || horizontal) { style.marginRight = right || horizontal; }

  return (
    <Box style={style} {...rest}>
      {children}
    </Box>
  );
};

Spacing.propTypes = propTypes;
Spacing.defaultProps = defaultProps;

export default Spacing;
