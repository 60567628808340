import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

const Image = ({
  alt,
  src,
  ...rest
}) => (
  <img
    alt={alt}
    src={src}
    {...rest}
  />
);

Image.propTypes = propTypes;

export default Image;
