import React from 'react';
import PropTypes from 'prop-types';

import Image from '../../components/Image';

import service from '../../services/profile';
import parseError from '../../utils/parseError';
import classnames from '../../utils/classnames';

import { gravatar } from '../../lib/auth';

import styles from './stylesheets/Avatar.module.css';

import placeholder from './placeholder.png';

const propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  size: PropTypes.number,
  showDefault: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  size: 240,
  showDefault: true,
  className: null,
};

class Avatar extends React.Component {
  constructor(props) {
    super(props);
    this.promise = null;
    this.state = {
      loading: true,
      url: null,
      exists: false,
      message: null,
    };
    this.onSuccess = this.onSuccess.bind(this);
  }

  componentDidMount() {
    const {
      email,
      size,
    } = this.props;

    this.promise = service.getAvatar(email, size);
    this.promise
      .then(({ data }) => this.onSuccess(data))
      .catch(error => this.setState({
        exists: false,
        loading: false,
        message: parseError(error),
      }));
  }

  onSuccess({ avatar_url: url }) {
    this.setState({
      url,
      exists: url !== null,
      loading: false,
    });
  }

  renderAvatar() {
    const {
      size,
      name,
      className,
    } = this.props;

    const {
      exists,
      url,
    } = this.state;

    const props = {
      width: size,
      height: size,
      className: classnames([
        styles.default,
        className,
      ]),
    };

    if (exists) {
      props.src = url;
    }

    return exists
      ? (<Image alt={name} {...props} />)
      : this.renderEmptyAvatar(props);
  }

  renderEmptyAvatar(props) {
    const { email } = this.props;
    const { message } = this.state;

    return (
      <Image
        alt={message}
        src={gravatar(email)}
        {...props}
      />
    );
  }

  renderLoading() {
    const {
      size,
      showDefault,
      className,
    } = this.props;

    if (showDefault) {
      return (
        <Image
          className={classnames([
            styles.loading,
            className,
          ])}
          src={placeholder}
          alt="Placeholder"
          width={size}
          height={size}
        />
      );
    }

    return null;
  }

  render() {
    const { loading } = this.state;

    const content = loading
      ? this.renderLoading()
      : this.renderAvatar();

    return content;
  }
}

Avatar.propTypes = propTypes;
Avatar.defaultProps = defaultProps;

export default Avatar;
