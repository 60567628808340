import React from 'react';

import { Avatar } from '../../pages/profile';
import Dropdown, {
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from '../../components/Dropdown';
import FlexBar from '../../components/FlexBar';
import Spacing from '../../components/Spacing';
import Only from '../../components/Only';

import OutsideClickHandler from '../../utils/outside-click';
import { isAdmin } from '../../lib/auth';

import { profilePropTypes } from '../../shapes/ProfileShape';

import styles from './stylesheets/Profile.module.css';

const propTypes = {
  ...profilePropTypes,
};

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.toggle = this.toggle.bind(this);
    this.onOutsideClick = this.onOutsideClick.bind(this);
  }

  onOutsideClick() {
    const { open } = this.state;
    if (open) {
      this.setState({
        open: false,
      });
    }
  }

  toggle() {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  }

  render() {
    const { email } = this.props;
    const { open } = this.state;

    return (
      <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
        <Dropdown show={open}>
          <DropdownToggle
            className={styles.toggle}
            onPress={this.toggle}
          >
            <FlexBar alignItems="center">
              <Spacing right={5}>
                <Avatar
                  size={20}
                  className={styles.avatar}
                  {...this.props}
                />
              </Spacing>
              Your profile
            </FlexBar>
          </DropdownToggle>
          <DropdownMenu
            right
            show={open}
          >
            <Only when={isAdmin(email)}>
              <DropdownItem to="/manage">Manage books</DropdownItem>
            </Only>
            <DropdownItem to="/books">Browse books</DropdownItem>
            <DropdownItem to="/profile">My profile</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </OutsideClickHandler>
    );
  }
}

Profile.propTypes = propTypes;

export default Profile;
