import * as api from '../../constants/api';
import axios from '../../utils/axios';

export const checkAuth = () => axios({
  method: 'get',
  headers: axios.defaults.headers,
  url: api.profile,
});

export const getProfile = () => axios({
  method: 'get',
  headers: axios.defaults.headers,
  url: api.profile,
});

export const getAvatar = (email, size = 32) => axios({
  method: 'get',
  url: `${api.gitlab.avatar}?email=${email}&size=${size}`,
});

export const getBooks = () => axios({
  method: 'get',
  headers: axios.defaults.headers,
  url: api.myBooks,
});

export default {
  getProfile,
  getAvatar,
};
