import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { ALIGN_START } from '../../constants/align-items';
import AlignItemsShape from '../../shapes/AlignItemsShape';
import styles from './stylesheets/Row.module.css';

const propTypes = {
  alignItems: AlignItemsShape,
  noGutter: PropTypes.bool,
  children: PropTypes.node,
};

const defaultProps = {
  alignItems: ALIGN_START,
  noGutter: false,
  children: null,
};

const Row = ({
  alignItems,
  noGutter,
  children,
}) => (
  <div className={classnames([
    styles.default,
    styles[`align-items-${alignItems}`],
    noGutter && styles['no-gutter'],
  ])}
  >
    {children}
  </div>
);

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;

export default Row;
