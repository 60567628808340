import React from 'react';

import Portal from '../../utils/portals';
import Box from '../Box';
import Loading from './Loading';
import styles from './stylesheets/Fixed.module.css';

const Fixed = () => (
  <Portal>
    <Box className={styles.wrapper}>
      <Box className={styles.content}>
        <Loading small />
      </Box>
    </Box>
  </Portal>
);

export default Fixed;
