import React from 'react';

import Container from '../../components/Container';
import FlexBar from '../../components/FlexBar';
import Only from '../../components/Only';
import Spacing from '../../components/Spacing';

import ProfileShape from '../../shapes/ProfileShape';

import Brand from './Brand';
import Profile from './Profile';

import styles from './stylesheets/Navbar.module.css';

const propTypes = {
  profile: ProfileShape,
};

const defaultProps = {
  profile: null,
};

const Navbar = ({
  profile,
}) => (
  <nav className={styles.default}>
    <Container>
      <FlexBar alignItems="center">
        <Brand />
        <Spacing left="auto">
          <Only when={profile !== null}>
            <Profile {...profile} />
          </Only>
        </Spacing>
      </FlexBar>
    </Container>
  </nav>
);

Navbar.propTypes = propTypes;
Navbar.defaultProps = defaultProps;

export default Navbar;
