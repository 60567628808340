import React from 'react';

import { LayoutSimple } from '../../template/Layout';
import Loading from './Loading';

const Page = () => (
  <LayoutSimple>
    <Loading small />
  </LayoutSimple>
);

export default Page;
