/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';

import Only from '../Only';
import isFunction from '../../utils/isFunction';
import Cell from './Cell';
import Row from './Row';
import HeadingShape from './shapes/HeadingShape';

const propTypes = {
  header: PropTypes.arrayOf(HeadingShape),
};

const defaultProps = {
  header: [],
};

class Head extends React.Component {
  renderCell(value) {
    return isFunction(value) ? value() : value;
  }

  renderColumns(header) {
    return header.map(({ name, value, options }) => (
      <Cell key={`.header-${name}`} type="header" {...options}>
        {this.renderCell(value)}
      </Cell>
    ));
  }

  render() {
    const { header } = this.props;

    return (
      <Only when={header.length}>
        <thead>
          <Row>
            {this.renderColumns(header)}
          </Row>
        </thead>
      </Only>
    );
  }
}

Head.propTypes = propTypes;
Head.defaultProps = defaultProps;

export default Head;
