import React from 'react';
import PropTypes from 'prop-types';

import AccessDenied from '../../pages/access-denied';
import { Route } from '../../utils/router';

import withAuth, { authPropTypes } from '../auth';

const propTypes = {
  ...authPropTypes,
  component: PropTypes.func.isRequired,
};

const PrivateRoute = ({
  auth: {
    isAuthenticated,
    loadingAuth,
    profile,
  },
  component: Component,
  ...rest
}) => {
  if (loadingAuth) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <AccessDenied />
    );
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Component
          profile={profile}
          {...props}
        />
      )}
    />
  );
};

PrivateRoute.propTypes = propTypes;

export default withAuth(PrivateRoute);
