import React from 'react';
import PropTypes from 'prop-types';

import styles from './stylesheets/Header.module.css';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

const Header = ({
  children,
  ...rest
}) => (
  <header
    className={styles.default}
    {...rest}
  >
    {children}
  </header>
);

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
