import React from 'react';

import Link from '../../components/Link';
import links from './links';
import styles from './stylesheets/Channels.module.css';

const Channels = () => (
  <div>
    {links.map(({
      id, url, title, Component,
    }) => (
      <Link
        key={`.channel-${id}`}
        href={url}
        title={title}
        className={styles.link}
      >
        <Component />
      </Link>
    ))}
  </div>
);

export default Channels;
