import {
  default as auth,
  authPropTypes,
  authDefaultProps,
  update,
  isAdmin,
  redirectToLogin,
  gravatar,
} from './auth';

export default auth;

export {
  authPropTypes,
  authDefaultProps,
  update,
  isAdmin,
  redirectToLogin,
  gravatar,
};
