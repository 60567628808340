import React from 'react';
import PropTypes from 'prop-types';

import Only from '../Only';
import Cell from './Cell';
import Row from './Row';
import HeadingShape from './shapes/HeadingShape';

const propTypes = {
  footer: PropTypes.arrayOf(HeadingShape),
};

const defaultProps = {
  footer: [],
};

const Footer = ({ footer }) => (
  <Only when={footer.length}>
    <tfoot>
      <Row>
        {footer.map(({ name, value, options }) => (
          <Cell
            key={`.footer-${name}`}
            type="header"
            {...options}
          >
            {value}
          </Cell>
        ))}
      </Row>
    </tfoot>
  </Only>
);

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
